import { getRouteUrl } from './lib'

/**
 * 房源搜索
 */
export function search(type, pathname, locale) {
  const slug = pathname || 'list_page-1'
  return getRouteUrl('/search/{type}/{slug}', { type, slug }, locale)
}

/**
 * 地图搜索
 */
export function seasrchMap(type, pathname, locale) {
  const slug = pathname || 'list_page-1'
  return getRouteUrl('/map/{type}/{slug}', { type, slug }, locale)
}

/**
 * 房源详情
 */
export function propertyDetail(slug, locale, baseUrl) {
  return getRouteUrl('/property/{slug}', { slug }, locale, baseUrl)
}

/**
 * 房源详情图片预览
 */
export function propertyDetailImages(slug, locale) {
  return getRouteUrl('/property/images/{slug}', { slug }, locale)
}

/**
 * 发布租房预览
 */
export function publishView(houseId, locale) {
  return getRouteUrl(`/property/view/${houseId}`, null, locale)
}

/**
 * 房源详情图片预览(通过id获取)
 */
export function propertyDetailByIdImages(houseId, locale) {
  return getRouteUrl(`/property/view/${houseId}/images`, null, locale)
}

/**
 * 房源详情图片预览
 */
export function claim(locale) {
  return getRouteUrl('/estimate/claim', null, locale)
}

/**
 * 区域分析
 */
export function suburbs(slug = 'page-1', locale = undefined) {
  return getRouteUrl(`/suburbs/${slug}`, null, locale)
}

/**
 * 区域分析详情
 */
export function suburb(slug, locale) {
  return getRouteUrl(`/suburb/${slug}`, null, locale)
}

/**
 * 学校详情
 */
export function schoolDetail(slug, locale) {
  return getRouteUrl(`/school/${slug}`, null, locale)
}

/**
 * 关于我们
 */
export function abouteUs(slug, locale) {
  return getRouteUrl(`/about-us/${slug}`, null, locale)
}

/**
 * 拍卖首页
 */
export function auctionHome(slug, locale) {
  const path = ['/auction-results']

  slug && path.push(slug)

  return getRouteUrl(path.join('/'), null, locale)
}

/**
 * 拍卖SEO
 */
export function auctionSeo(slug, locale) {
  const path = ['/auction-results/index']

  slug && path.push(slug)

  return getRouteUrl(path.join('/'), null, locale)
}
